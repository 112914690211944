.mission-info-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: sub;
}

.mission-info-text::before {
    content: ' ';
}

.mission-info .row {
    padding-bottom: 10px;
}

.mission-info {
    padding-top: 10px;
    & > .form-row {
        padding: 5px;
    }
}
