.customer-location-icon {
    mask: url('../svg/marker.svg') no-repeat center / contain;
    -webkit-mask: url('../svg/marker.svg') no-repeat center / contain;
    background-color: #C80000;
}

.vehicle-location-icon {
    mask: url('../svg/truck.svg') no-repeat center / contain;
    -webkit-mask: url('../svg/truck.svg') no-repeat center / contain;
    background-color: #003F91;
}

.vehicle-location-marker {
    background: none !important;
    border: none !important;
}

.icon {
    width: 30px !important;
    height: 30px !important;
}

.pulse {
    -webkit-animation: pulse 1s linear infinite;
    -moz-animation: pulse 1s linear infinite;
    -ms-animation: pulse 1s linear infinite;
    animation: pulse 1s linear infinite;
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.9);
        -o-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

@-moz-keyframes pulse {
    0% {
        -moz-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -moz-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -moz-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-ms-keyframes pulse {
    0% {
        -ms-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
    100% {
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
