$validated-color: #89C53F;
$warning-color: #c87800;

.status-icon {
    height: 50px;
    width: 50px;
    display: block;
}

.status-icon-current, .status-icon-validated {
    fill: $validated-color
}

.status-icon-current {
    height: 70px;
    width: 70px;
}

.status-icon-warning {
    fill: $warning-color;
}

.status-icon-next {
    fill: darkgrey;
}

.status {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.status-link {
    border: 3px darkgrey dashed;
    width: 20%;
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;

    &.warning {
        border-color: $warning-color !important;
    }
    &.validated {
        border-color: $validated-color !important;
        border-style: solid;
    }
}

.status-text-separator {
    width: 20%;
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
}

.status-text {
    width: 50px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.status-eta-container {
    text-align: center;
}

.status-text-current {
    width: 70px;
}


