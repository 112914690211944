$background-color: #FBFAFB;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Phone css
@media (max-width: 767px) {
  #map, .form-container {
    width: 100%;
    height: 73.5vh;
    position: relative;
    margin-top: 20vh;
  }

  .status-link, .status:not(.status-current), .status-text:not(.status-text-current), .status-text-separator {
    display: none !important;
  }

  .status-container{
    position: sticky !important;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-left: 5px !important;
    &>.row {
      margin-right: 0;
      margin-left: 0;
      display: inline-block;
      padding-left: 0;
    }
    & .row:nth-child(2) {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .status-eta-container {
    text-align: initial !important;
    position: absolute !important;
    top: 0;
    left: 0;
    padding-left: 5px !important;
    padding-right: 5px !important;
    min-height: 13vh !important;
  }

  .status-text-current {
    width: initial !important;
    white-space:nowrap;
    font-size: x-large;
  }

  .eta {
    font-size: x-large;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .phone {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    background-color: $background-color;
    text-align: center;
  }

  .details {
    display: none;
    text-align: initial !important;
    margin-right: 10px;
    margin-left: 10px;
  }

  .visible {
    display: block !important;
  }

  #details-trigger {
    color: #FBFAFB;
    background-color: #13B0CA;
    font-size: large;
    padding: 15px;
  }

  .status-icon {
    height: 50px !important;
    width: 50px !important;
  }
}

// Web browser css
@media (min-width: 768px) {
  #map, .form-container {
    width: 100%;
    height: 75vh;
  }

  .form-container {
    padding-top: 30px
  }

  .phone {
    display: none;
  }

  .position {
    font-size: 20px;
  }
}
