$spinner-color: #00ADC7;
$spinner-background: #f3f3f3;

.view-container {
  border-radius:5px;
  padding: 15px;
}

.spinner-container {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 50px;
}

.spinner {
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 16px solid $spinner-background;
  border-top: 16px solid $spinner-color;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
